<template>
  <div>
  
  </div>
</template>

<script>


export default {
  name: "pageCadastro",
  props: {},
  components:{},
  mounted() {
  },
  created() {


  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>

